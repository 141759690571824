<div *ngIf="data != null">
  <ng-container>
    <span *ngIf="options.isDaily" class="aft-tacho__label aft-tacho__label--big dateFont"
      [ngClass]="{'text-danger': data?.holiday?.isHoliday}">
      {{getDayFromTimestamp(data.date)}}
    </span>
    <span class="hidden-xs hidden-sm aft-tacho__label" [ngClass]="{'text-danger': data?.holiday?.isHoliday}">
      {{data?.holiday?.holiday}}
    </span>
    <div *ngIf="!options.isDaily" class="hidden-xs hidden-sm aft-tacho__title tacho_title">{{data.title}}</div>
    <i *ngIf="options.isDaily && data != null && data.infrigments != null" id="infr{{data.date}}"
      (mouseenter)="infrigmentsDetails($event, data.date)" (mouseleave)="infrigmentsDetails($event, data.date)"
      class="fa fa-gavel fa-2x aft-text-danger__darkcolor fa-pull-right"></i>
    <i *ngIf="options.isDaily && data != null && data.places != null" id="place{{data.date}}"
      (mouseleave)="placesDetails($event, data.date)" (mouseenter)="placesDetails($event, data.date)"
      class="fa fa-globe fa-2x aft-text-success__darkcolor fa-pull-right"></i>

    <i class="fa-solid fa-ferry fa-2x aft-text-success__darkcolor fa-pull-right" id="scope{{data.date}}"
      *ngIf="data.ferryTrainIntervals?.length > 0"
      [ngClass]="{'aft-tacho__skewed_bi-color': data.ferryTrainIntervals?.length > 0 && data.outOfScopeIntervals?.length > 0,
                'aft-tacho__skewed_out-of-scope-color': data.ferryTrainIntervals?.length === 0 && data.outOfScopeIntervals?.length > 0,
                'aft-tacho__skewed_ferry-train-color': data.ferryTrainIntervals?.length > 0 && data.outOfScopeIntervals?.length === 0}"
      (mouseenter)="currentScopeDetails($event, data.date)" (mouseleave)="currentScopeDetails($event, data.date)"></i>
    <i class="fa-sharp fa-solid fa-road-barrier fa-2x aft-text-success__darkcolor fa-pull-right"
      *ngIf="data.outOfScopeIntervals?.length > 0" id="scope{{data.date}}"
      [ngClass]="{'aft-tacho__skewed_bi-color': data.ferryTrainIntervals?.length > 0 && data.outOfScopeIntervals?.length > 0,
                'aft-tacho__skewed_out-of-scope-color': data.ferryTrainIntervals?.length === 0 && data.outOfScopeIntervals?.length > 0,
                'aft-tacho__skewed_ferry-train-color': data.ferryTrainIntervals?.length > 0 && data.outOfScopeIntervals?.length === 0}"
      (mouseenter)="currentScopeDetails($event, data.date)" (mouseleave)="currentScopeDetails($event, data.date)"></i>

    <div class="row aft-margin-bottom-5" *ngIf="data != null">
      <div
        [ngClass]="{'leftContainerDaily': options.isDaily, 'col-xs-4 col-md-offset-2 leftContainer': !options.isDaily}">
        <span class="af3 af3-drive aft-font-size-20 aft-tacho__driving__color"
          pTooltip="{{'tacho.drive' | translate }}" tooltipPosition="left">
          <fa-icon [icon]="faCircleRadiation"></fa-icon>
        </span>
        <span class="aft-vertical-align-super">{{data?.driveduration}}</span>
      </div>
      <div [ngClass]="{'rightContainerDaily': options.isDaily, 'col-xs-4 rightContainer': !options.isDaily}">
        <span class="af3 af3-work aft-font-size-20 aft-tacho__working__color"  pTooltip="{{'tacho.work' | translate }}" tooltipPosition="left">
          <fa-icon [icon]="faScrewdriverWrench"></fa-icon></span>
        <span class="aft-vertical-align-super">{{data?.workduration}}</span>
      </div>
    </div>
    <div class="row" *ngIf="data != null">
      <div
        [ngClass]="{'leftContainerDaily': options.isDaily, 'col-xs-4 col-md-offset-2 leftContainer': !options.isDaily}">
        <span class="af3 af3-rest aft-font-size-20 aft-tacho__resting__color"  pTooltip="{{'tacho.rest' | translate }}" tooltipPosition="left">
          <fa-icon [icon]="faBed"></fa-icon></span>
        <span class="aft-vertical-align-super">{{data.restduration}}</span>
      </div>
      <div [ngClass]="{'rightContainerDaily': options.isDaily, 'col-xs-4 rightContainer': !options.isDaily}">
        <span class="af3 af3-available aft-font-size-20 aft-tacho__available__color"
          pTooltip="{{'tacho.available' | translate }}" tooltipPosition="left">
          <fa-icon [icon]="faSquareCheck"></fa-icon></span>
        <span class="aft-vertical-align-super">{{data.availableduration}}</span>
      </div>
    </div>
    <div id="scope{{data.date}}" class="aft-tacho__skewed-bottom-left"
      [ngClass]="{'aft-tacho__skewed_bi-color': data.ferryTrainIntervals?.length > 0 && data.outOfScopeIntervals?.length > 0,
                 'aft-tacho__skewed_out-of-scope-color': data.ferryTrainIntervals?.length === 0 && data.outOfScopeIntervals?.length > 0,
                 'aft-tacho__skewed_ferry-train-color': data.ferryTrainIntervals?.length > 0 && data.outOfScopeIntervals?.length === 0}"
      *ngIf="data.ferryTrainIntervals?.length > 0 || data.outOfScopeIntervals?.length > 0"
      (mouseenter)="currentScopeDetails($event, data.date)" (mouseleave)="currentScopeDetails($event, data.date)">
    </div>

    <dx-popover [target]="targetElementInfr" [(visible)]="isVisibleInfr" position="bottom" [width]="300"
      [showTitle]="true" title="{{'global.infrigments' | translate}}">
      <table class="aft-tacho__info-activity__table">
        <tr *ngFor="let row of data.infrigments; let i = index;">
          <td nowrap>
            <span>{{row.timings.infrigmentInstant | date:'HH:mm'}}</span>
          </td>
          <td>&nbsp;</td>
          <td nowrap>
            <span>{{'tacho.infrigmentsType.' + row.infrigmentType | translate}}</span>
          </td>
        </tr>
      </table>
    </dx-popover>


    <dx-popover [target]="targetElementPlace" [(visible)]="isVisiblePlaces" position="bottom" [width]="300"
      [showTitle]="true" title="{{ 'global.placesData' | translate }}">
      <table class="aft-tacho__info-activity__table">
        <tr *ngFor="let row of data.places; let i = index">
          <td nowrap>
            <span>{{ row.startActivity | date:'HH:mm' }}</span>
          </td>
          <td>&nbsp;</td>
          <td nowrap>
            <span>{{ row.country }}</span>
          </td>
        </tr>
      </table>
    </dx-popover>
    <dx-popover [target]="targetElementScope" [(visible)]="isVisibleScope" position="bottom" [width]="300">
      <!-- <div [dxScrollView]="scrollViewOptions" [attr.data-options]="{dxTemplate: { name: 'infoScope' }}"> -->
      <div>
        <div *ngIf="data.ferryTrainIntervals?.length > 0">
          <div class="aft-padding-bottom-5">
            <span>{{ 'global.ferryTrainScope' | translate }}</span>
          </div>
          <table class="aft-tacho__info-activity__table">
            <tr *ngFor="let row of data.ferryTrainIntervals; let i = index">
              <td nowrap>
                <b>{{ 'global.start' | translate }}</b>
              </td>
              <td nowrap>
                <span>{{ row.start | date:'HH:mm' }}</span>
              </td>
              <td nowrap>
                <b>{{ 'global.end' | translate }}</b>
              </td>
              <td nowrap>
                <span>{{ row.end | date:'HH:mm' }}</span>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="data.outOfScopeIntervals?.length > 0 && data.ferryTrainIntervals?.length > 0"
          style="height: 5px; width: 10px;"></div>
        <div *ngIf="data.outOfScopeIntervals?.length > 0">
          <div class="aft-padding-bottom-5">
            <span>{{ 'global.outOfScope' | translate }}</span>
          </div>
          <table class="aft-tacho__info-activity__table">
            <tr *ngFor="let row of data.outOfScopeIntervals; let i = index">
              <td nowrap>
                <b>{{ 'global.start' | translate }}</b>
              </td>
              <td nowrap>
                <span>{{ row.start | date:'HH:mm' }}</span>
              </td>
              <td nowrap>
                <b>{{ 'global.end' | translate }}</b>
              </td>
              <td nowrap>
                <span>{{ row.end | date:'HH:mm' }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </dx-popover>
  </ng-container>


  <dx-scroll-view [useNative]="true">
  </dx-scroll-view>




</div>
