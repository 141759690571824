import { Component, Input, OnChanges, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CURRENT_USER_ENDPOINT } from 'src/app/core/config/config.constants';
import { CurrentUserInfo } from 'src/app/core/models/currentUserInfo';
import { ApiService } from 'src/app/core/services/api.service';
import { Subscription } from 'rxjs';
import { FilterService } from '../../filter/filter.service';
import { AppStateInterface } from 'src/app/appState.interface';
import { Store } from '@ngrx/store';
import * as PostActions from '../../../../core/store/actions'

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnChanges, OnDestroy {
  @Input() displayNav: boolean = true;
  @ViewChild('sidenav') sideNav: any;
  open = true;
  stylesheetString = 'aft';
  currentUserInfo: CurrentUserInfo;
  subscription: Subscription;
  menuList: any;
  constructor(
    private dataService: DataService,
    private apiService: ApiService,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private router: Router,
    // private filterService: FilterService,
    private store: Store<AppStateInterface>
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    if (this.sideNav) {
      this.toggleNav();
    }
  }

  ngOnInit() {
    this.menuList = this.getMenuInfo();
    //this.menuList[0].child.push({label: 'trailerInstallation', w: true, child: Array(0), order: 11})
    this.stylesheetString = this.utilsService.getStylesheetString();
    this.currentUserInfo = this.dataService.getCurrentUserInfo();
    //#FZ Demo mode delte some voice
    this.demoModeRemoveMenuList();
    this.getCurrent(false);
    this.subscription = this.dataService.refreshNavEvent.subscribe({
      next: (v) => {
        this.getCurrent();
      },
    });
    //#5556 FZ ADD NEW MENU ITEM FOR TRAILER
    // this.menuList.push({label: 'userAppManagement', w: true, child: Array(0), order: 75})

    /*** da decommentare se si vuole aggiungere una voce di menu custom ****/
    
    // let menu = JSON.parse(JSON.stringify(this.menuList));
    // this.menuList = [];
    // menu[0].child.push({ label: 'tachographErrorTracking', w: true, child: Array(0), order: 99 });
    // this.menuList = menu;

  }

  toggleNav() {
    this.sideNav.toggle();
  }

  getMenuInfo() {
    let currentUserInfo = this.dataService.getCurrentUserInfo();
    let orderedMenu = _.orderBy(currentUserInfo?.menu, ['order'], ['asc']);
    return orderedMenu;
  }

  getCurrent(refresh: boolean = true) {
    this.dataService.getCurrent.subscribe((currentUserInfo) => {
      currentUserInfo = currentUserInfo as CurrentUserInfo;
      this.dataService.setCurrentUserInfo(currentUserInfo);
      this.currentUserInfo = currentUserInfo;
      localStorage.setItem('user', JSON.stringify(currentUserInfo));

      // let customer = localStorage.getItem('selectedCustomerCache')


      // if (!customer){
      //   localStorage.setItem('selectedCustomerCache', JSON.stringify(currentUserInfo.customer));

      // }


      //FZ togliere da qui e mettere qiuando caricato user
      //  this.dataService.setLanguage(currentUserInfo.user.preferences.language.label);
      // this.translate.setDefaultLang(this.dataService.getLanguage());
      this.currentUserInfo = this.dataService.getCurrentUserInfo();
      if (refresh) {
        this.getMenuInfo();
        this.router.navigate(['/app/home']).then((_) => window.location.reload());
      }

    },
      error => {
        this.utilsService.showErrorPopup(error);
      });
  }



  demoModeRemoveMenuList() {
    if (this.currentUserInfo.user?.isDemo) {

      this.menuList.forEach((item, index) => {
        if (item.label == 'realtimeDriver') this.menuList.splice(index, 1)
      });

      let menuAmm: any = [];
      this.menuList.forEach((item, index) => {
        if (item.label == 'administration') {
          for (let i = 0; i < this.menuList[index].child.length; i++) {
            if (this.menuList[index].child[i].label != 'users')
              menuAmm.push(this.menuList[index].child[i])
            //console.log(this.menuList[index].child[i])
          }
          this.menuList[index].child = menuAmm;
        }
      })



    }
  }
}
